<template>
  <div>
    <!-- 商品结构 -->
    <filter-view :loading="loading" @submit="loadEchart">
      <store-Filter :show-opt="showOpt" :query="query" @init="loadEchart" />
    </filter-view>
    <div class="result">
      <div class="padding-10 fs-small fc-g">分析结果</div>
      <div class="round-panel bc-w">
        <div v-if="store && store.length">
          <div class="c h sb">
            <div class="no-flex padding-10">显示图表前</div>
            <el-input-number v-model="showRankNum" size="mini" :min="1" :max="1000" :step="1" :precision="0" @change="draw" />
          </div>
          <div class="sep-t" ref="gdBarChart" style="height:320px;"></div>
          <div class="sep-t" ref="gdPieChart" style="height:320px;"></div>
        </div>
        <div class="fc-g ta-c padding-10" v-if="!store || !store.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
      <!-- 表格 -->
      <template v-if="store && store.length > 0">
        <div class="padding-10 fs-small fc-g">数据列表</div>
        <div class="round-panel bc-w">
          <div v-for="o in pagedStore" :key="o.id" class="padding-05 sep-b">
            <div class="fs-large bold padding-05" style="width: 100%">{{ o.name }}</div>
            <template v-if="isReported">
              <div class="h c wrap fs-small lh-150">
                <div class="padding-05" style="width: 50%">
                  <div>销售金额</div>
                  <div>{{ $price(o.realCheckSumMoney) || "-" }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>销售数量</div>
                  <div>{{ o.checkSaleNumber || "-" }}</div>
                </div>

                <div class="padding-05" style="width: 50%">
                  <div>发货金额</div>
                  <div>{{ $price(o.checkSendMoney) || "-" }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>发货数量</div>
                  <div>{{ o.checkSendNumber || "-" }}</div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="h c wrap fs-small lh-150">
                <div class="padding-05" style="width: 50%">
                  <div>销售金额</div>
                  <div>{{ $price(o.realSumMoney) }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>销售数量</div>
                  <div>{{ o.saleNumber || "-" }}</div>
                </div>

                <div class="padding-05" style="width: 50%">
                  <div>发货金额</div>
                  <div>{{ $price(o.sendMoney) }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>发货数量</div>
                  <div>{{ o.sendNumber || "-" }}</div>
                </div>
              </div>
            </template>
          </div>
          <div class="ta-c">
            <el-pagination class="h5-pagination" :total="totalElements" :current-page.sync="page" :page-size="size" layout="prev, pager, next" hide-on-single-page />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import filterView from "../assembly/filter";
import storeFilter from "../assembly/filter/store";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    storeFilter,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      page: 1,
      size: 6,
      totalElements: null,
      store: [],
      showRankNum: 5,
      isReported: false,
      showOpt: {
        showGoodsType: true, //商品分类
        showDimension: false, //分析维度隐藏
        showYearonyear: false, //同比
        showMonth: false, //
        showCommodity: true, //指定商品
        showRangGoods: true, //商品范围
        showRank: false, //显示排名对象
        showRankGist: true, //显示只统计已上报
        showCompareyear: false,
        showLevel: true, //汇总级别
        showCategory: false, //对比类型
        showReported: true, //仅显示已上报
      },
      query: {
        treeNodeId: null,
        treeNodeType: null,
        dateGroup: "day", //分析维度
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        brandId: null, //品牌
        seriesId: null, //系列
        categoryId: null, //分类
        goods: null, //指定商品
        reportOnly: false, //仅显示已上报
        shopAreaGroup: "area", //排名对象
        saleValueGroup: "realSumMoney", //排名依据
        goodsGroup: "brandId", //汇总级别
        page: 0,
        size: 10000,
      },
      chart: {
        gdBarChart: null,
        gdPieChart: null,
      },
    };
  },
  watch: {
    store() {
      this.page = 1;
    },
  },
  computed: {
    pagedStore() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.store.length) {
        len = this.store.length - start;
      }
      return this.store.slice(start, start + len);
    },
  },
  methods: {
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      if (!data.reportOnly) delete data.reportOnly;
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      if (data.goods && data.goods.length)
        data.skuIds = data.goods.map((o) => o.id);
      delete data.goods;
      return data;
    },
    loadEchart() {
      if (this.query.reportOnly == false) {
        this.isReported = false;
      } else {
        this.isReported = true;
      }
      let data = Object.assign({}, this.makeParams());
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/shop/sale/goods",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.store = res.content;
          this.totalElements = res.totalElements;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.store = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    draw(val) {
      if (!val) {
        this.showRankNum = 5;
      }
      this.drawGdBarChart();
      this.drawGdPieChart();
    },
    drawGdBarChart() {
      if (!this.chart.gdBarChart) {
        this.chart.gdBarChart = echarts.init(this.$refs.gdBarChart, "light");
      }
      let xName = [];
      let xData = [];
      let yName = [];
      let svgp = [];
      let seriesData = [];
      if (
        this.query.saleValueGroup == "saleNumber" ||
        this.query.saleValueGroup == "checkSaleNumber" ||
        this.query.saleValueGroup == "sendNumber" ||
        this.query.saleValueGroup == "checkSendNumber"
      ) {
        yName.push("数量");
        (this.store || []).forEach((o) => {
          xData.push(o.name);
          seriesData.push(o[this.query.saleValueGroup]);
        });
      } else {
        yName.push("金额(万元)");
        (this.store || []).forEach((o) => {
          xData.push(o.name);
          seriesData.push(
            Math.round(o[this.query.saleValueGroup] * 0.000001 * 100) / 100
          );
        });
      }
      switch (this.query.goodsGroup) {
        case "brandId":
          xName.push("品牌结构");
          break;
        case "seriesId":
          xName.push("系列结构");
          break;
        case "goodsCategoryId":
          xName.push("分类结构");
          break;
        case "spuId":
          xName.push("商品结构");
          break;
      }
      switch (this.query.saleValueGroup) {
        case "realSumMoney":
          svgp.push("销售金额");
          break;
        case "realCheckSumMoney":
          svgp.push("已上报销售金额");
          break;
        case "saleNumber":
          svgp.push("销售数量");
          break;
        case "checkSaleNumber":
          svgp.push("已上报单品数量");
          break;

        case "sendMoney":
          svgp.push("发货金额");
          break;
        case "checkSendMoney":
          svgp.push("已上报发货金额");
          break;
        case "sendNumber":
          svgp.push("发货数量");
          break;
        case "checkSendNumber":
          svgp.push("已上报发货数量");
          break;
      }
      this.chart.gdBarChart.clear();
      this.chart.gdBarChart.setOption({
        title: {
          text: xName + svgp + "前" + this.showRankNum,
          left: "center",
        },
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          confine: true,
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: 50,
          right: 20,
          bottom: 50,
          top: 50,
        },
        xAxis: [
          {
            type: "category",
            data: xData.slice(0, this.showRankNum),
            axisLabel: {
              formatter: function (value) {
                if (value.length > 5) {
                  return value.substr(0, 5) + "…";
                }
                return value;
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: yName,
          },
        ],
        series: [
          {
            name: xName,
            type: "bar",
            data: seriesData.slice(0, this.showRankNum),
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                },
              },
            },
          },
        ],
        dataZoom: {
          show: xData.slice(0, this.showRankNum).length > 5, // 为true 滚动条出现
          filterMode: "weakFilter",
          type: "slider", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
          height: 15,
          start: 0,
          end: (5 / xData.slice(0, this.showRankNum).length) * 100,
          bottom: 10,
          handleIcon:
            "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
          handleSize: "80%",
          zoomLock: true,
          showDetail: false,
        },
      });
    },
    // 饼状图
    drawGdPieChart() {
      if (!this.chart.gdPieChart) {
        this.chart.gdPieChart = echarts.init(this.$refs.gdPieChart, "light");
      }
      let xName = [];
      let svgp = [];
      let arry = this.store;
      let sgp = this.query.saleValueGroup;
      let newArray = this.store.map((o) => {
        if (
          sgp == "saleNumber" ||
          sgp == "checkSaleNumber" ||
          sgp == "sendNumber" ||
          sgp == "checkSendNumber"
        ) {
          return {
            name: o.name,
            value: o[sgp] == 0 ? null : o[sgp], //数据为0不在图案中显示出来
          };
        } else {
          return {
            name: o.name,
            value: o[sgp] == 0 ? null : Math.round(o[sgp] * 0.01 * 100) / 100, //数据为0不在图案中显示出来
          };
        }
      });
      switch (this.query.goodsGroup) {
        case "brandId":
          xName.push("品牌");
          break;
        case "seriesId":
          xName.push("系列");
          break;
        case "goodsCategoryId":
          xName.push("分类");
          break;
        case "spuId":
          xName.push("商品");
          break;
      }
      switch (this.query.saleValueGroup) {
        case "realSumMoney":
          svgp.push("销售金额");
          break;
        case "realCheckSumMoney":
          svgp.push("已上报销售金额");
          break;
        case "saleNumber":
          svgp.push("销售数量");
          break;
        case "checkSaleNumber":
          svgp.push("已上报单品数量");
          break;
        case "sendMoney":
          svgp.push("发货金额");
          break;
        case "checkSendMoney":
          svgp.push("已上报发货金额");
          break;
        case "sendNumber":
          svgp.push("发货数量");
          break;
        case "checkSendNumber":
          svgp.push("已上报发货数量");
          break;
      }

      this.chart.gdPieChart.clear();
      this.chart.gdPieChart.setOption({
        title: {
          text: xName + svgp + "汇总分析前" + this.showRankNum,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          confine: true,
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        series: [
          {
            name: xName + svgp,
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: newArray.slice(0, this.showRankNum),
            label: {
              formatter: function (p) {
                if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                else return p.name;
              },
            },
          },
        ],
      });
    },
  },
};
</script>
